export default [
  { name: '品牌價值', imgSrc: '', subTitle: 'BRAND', section: 'section2', svgSrc: '', offset: -100 },
  { name: '核心地段', imgSrc: '', subTitle: 'LOCATION', section: 'section3', svgSrc: '', offset: -100 },
  { name: '建築美學', imgSrc: '', subTitle: 'ARCHITECTURE', section: 'section4', svgSrc: '', offset: -100 },
  { name: '規劃設計', imgSrc: '', subTitle: 'DESIGN', section: 'section5', svgSrc: '', offset: -100 },
  { name: '預約賞屋', imgSrc: '', subTitle: 'RESERVE', section: 'contact', svgSrc: '', mobileOffset: 0 },
]

// 黃金地段：location
// 交通機能：traffic
// 生活版圖：life、job、vacation
// 優質團隊：architecture、brand、五個絕美承諾
// 預約鑑賞：（總價988萬起、後面的預約參觀）
// 基本資料
