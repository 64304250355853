export default {
  address: '新北市新店區北新路一段110號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.8925090794046!2d121.5400699154461!3d24.969771547016155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346801f49b6b1155%3A0x9e06fa553dddb8f9!2zMjMx5paw5YyX5biC5paw5bqX5Y2A5YyX5paw6Lev5LiA5q61MTEw6Jmf!5e0!3m2!1szh-TW!2stw!4v1586257522850!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/m5yB2m8hxTh9jhzo7',
  phone: '02-29156111',
  fbLink: 'https://www.facebook.com/w25prince/',
  fbMessage: 'https://m.me/w25prince',
  caseName: 'W太子',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '太子建設'],
    ['建築規劃', '呂建勳建築師事務所'],
    ['經紀人', '許惠娟（98）北縣字第001948號'],
    ['建築代銷', 'Z.A 政安廣告'],
    ['使用執照', '108店使字第00294號'],
  ],

  gtmCode: ['TSSWDJT'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
